.modal-container {
	@include fadeEffect($visibleState: '&.open');
	display: block;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	.modal-overlay {
		background-color: $bg-alpha;
		filter: blur(2px);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%; }

	.modal-inner {
		background-color: $body-bg;
		border: 1px solid $gray;
		box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.25);
		max-height: 80%;
		overflow: auto;
		padding: 2em 1em;
		position: absolute;
		top: 10%;
		left: 15%;
		width: 70%; }

	.closeButton {
		height: 1.5rem;
		width: 1.5rem; } }

.react-confirm-alert-blur {
	filter: none;

	&:after {
		content: ' ';
		display: block;
		filter: blur(2px);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%; } }

#react-confirm-alert {
	.react-confirm-alert-overlay {
		animation-delay: 0s;
		animation-duration: $defaultTransitionTime;
		z-index: 101;

		.react-confirm-alert-body {
			background-color: $white;
			border: 1px solid $gray;
			border-radius: 0;
			box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.25);
			color: $black;
			font-family: $fontOpenSans;
			text-align: center;

			.react-confirm-alert-button-group {
				display: flex;
				align-items: stretch;
				justify-content: space-evenly;

				button {
					@include btn-primary;
					border-radius: 0;

					&:first-of-type {
						background-color: $red;

						&:hover {
							background-color: darken($red, 10%); } }

					&:last-of-type {
						background-color: $black;

						&:hover {
							background-color: darken($black, 10%); } } } } } } }
