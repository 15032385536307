// Gombok
@mixin btn-default {
	box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.25);
	border: 0;
	margin: 0.5em 0;
	text-transform: uppercase;
}

@mixin btn-primary {
	@include btn-default;
	@include btn-variant($btn-variant-primary...);
}

@mixin btn-secondary {
	@include btn-default;
	@include btn-variant($btn-variant-secondary...);
	font-weight: 600;
}


// Form-elemek
@mixin textField-default {
	border-style: solid;
}

@mixin textField-primary {
	@include textField-default;
	@include textField-variant($textField-variant-primary...);
}

@mixin createX($thickness: 3px, $color: $black) {
	display: block;
	position: relative;
	transform: rotate(-45deg);
	&:before, &:after {
		background-color: $color;
		content: '';
		display: block;
		position: absolute;
	}
	&:before {
		height: $thickness;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%
	}
	&:after {
		height: 100%;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		width: $thickness
	}
	@content
}

@mixin fadeEffect($timing: $defaultTransitionTime, $itemSelector: '', $visibleState: '&:hover', $zStart: -100, $zEnd: 100) {
	@if ($itemSelector != '') {
		#{$itemSelector} {
			opacity: 0;
			visibility: hidden;
			transition: z-index $timing step-end, visibility $timing step-end, opacity $timing linear;
			z-index: $zStart;
		}
	} @else {
		opacity: 0;
		visibility: hidden;
		transition: z-index $timing step-end, visibility $timing step-end, opacity $timing linear;
		z-index: $zStart;
	}
	#{$visibleState} {
		opacity: 1;
		transition: z-index $timing step-start, visibility $timing step-start, opacity $timing linear;
		visibility: visible;
		z-index: $zEnd;
	}
	@content
}