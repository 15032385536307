.notificationBar {
	@include fadeEffect($visibleState: '&.open', $zEnd: 100000);
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0.5em 1em;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	.refresh-link {
		font-weight: bold;
		margin-right: 2em;
		transition: $defaultTransitionTime color;

		&:hover {
			color: $white; } }

	.closeButton {
		&:before, &:after {
			transition: $defaultTransitionTime background-color; }

		&:hover {
			&:before, &:after {
				background-color: $white; } } }

	.message {
		font-weight: bold;
		padding: 0 0.5em;
		margin: 0;

		p {
			margin: 0; } }

	&.success {
		background-color: $color-success; }

	&.warning {
		background-color: $yellow; }

	&.error, &.fatal {
		background-color: $red; } }
