form.form .form-field.full-width.assignment-field {
	display: flex;
	align-items: flex-start;
	flex: 1 1 auto;

	.table-title {
		text-align: center;
		margin-bottom: 0; }

	.table-left, .table-right {
		height: 100%;
		padding: 0 1em;
		width: auto; }

	.table-left {
		flex-basis: 60%; }

	.table-right {
		flex-basis: 40%; }

	.btn-assignment {
		align-self: center;
		line-height: 1;
		margin-bottom: 1em;
		width: auto;

		&.btn-assign {
			@include iconAfter(unquote("\"#{ $fa-var-arrow-right }\""));
			background-color: $green-light;

			&:after {
				padding-left: 1em; } }

		&.btn-unassign {
			@include iconBefore(unquote("\"#{ $fa-var-arrow-left }\""));
			background-color: $red;

			&:before {
				padding-right: 1em; } } } }
