.page-adwords {

	.sub-header, .sub-footer {
		flex: 0 1 auto; }

	.modal-container.excel {
		form.form {
			> * {
				flex-basis: 100%; }

			.date-ranges {
				.date-range-radios {
					display: flex;
					align-items: center;

					.inputMaskWrapper {
						margin-left: 1em;

						&:first-of-type {
							margin-left: 0; }

						.inputMask {
							height: 1.25rem; } }

					label {
						padding-left: 0.5em; }

					input {
						margin: 0; } }


				.date-ranges-title {
					flex-basis: 120px;
					margin: 0; }

				.custom-date-range {
					display: none;
					align-items: center;
					padding: 1em 2em;

					.custom-ranges-title {
						margin: 0;
						padding-right: 1em; }

					.react-datepicker-wrapper {
						margin-right: 1em; }

					.react-datepicker {
						button {
							border-width: 0.75rem;
							box-shadow: none;
							margin: 0;

							&:hover {
								background-color: transparent; } } }

					input {
						margin: 0; }

					&.show {
						display: flex; } } }

			.checkbox-row {
				display: flex;
				align-items: center;
				padding-top: 1em;

				.checkbox-row-title {
					flex-basis: 120px;
					margin: 0;
					padding-right: 1em; }

				input {
					margin: 0; }

				.inputMaskWrapper {
					margin-right: 0.5em; } }

			.form-buttons {
				padding-top: 2em; } } } }
