.ag-grid-custom {
	display: flex;
	flex: 1 1 auto;
	flex-flow: column;
	width: 100%;

	.ag-header-container {
		.ag-header-row {
			.ag-header-cell {
				border-color: $blue;

				&:after {
					border-color: $blue; }

				.ag-header-icon {
					display: flex;
					align-items: center;

					.ag-icon {
						background-size: 20px 20px;
						opacity: 1;
						height: 20px;
						width: 20px; } }

				.ag-header-cell-menu-button {
					padding-top: 6px; } } } }

	.ag-row {
		.ag-cell {
			display: flex;
			align-items: center; } }

	.row-select {
		justify-content: center;
		outline: none; }

	.boolean-cell {
		@include iconAfter;
		font-size: 1.375em;
		text-align: center;

		&.true:after {
			color: $green;
			content: unquote("\"#{ $fa-var-check-circle }\""); }

		&.false:after {
			color: $red;
			content: unquote("\"#{ $fa-var-times-circle }\""); } }

	.grid-cell-left {
		justify-content: flex-start;
		text-align: left; }

	.grid-cell-center {
		justify-content: center;
		text-align: center; }

	.grid-cell-right {
		justify-content: flex-end;
		text-align: right; }

	.row-actions {
		outline: none;

		.grid-button-wrapper {
			display: flex;
			align-items: flex-end;

			.grid-button {
				cursor: pointer;
				font-size: 1.375rem;
				padding: 0 0.25em;
				text-decoration: none;
				text-shadow: 1px 1px $gray;

				&.edit {
					color: $blue;
					@include iconAfter(unquote("\"#{ $fa-var-edit }\"")); }

				&.delete {
					color: $red;
					@include iconAfter(unquote("\"#{ $fa-var-trash }\"")); }

				&.download {
					color: $blue;
					@include iconAfter(unquote("\"#{ $fa-var-download }\"")); }

				&.excel-active {
					color: $green;
					@include iconAfter(unquote("\"#{ $fa-var-file-excel }\"")); }

				&.excel-inactive {
					color: $orange;
					@include iconAfter(unquote("\"#{ $fa-var-file-excel }\"")); }

				&.vat {
					color: $orange;
					@include iconAfter(unquote("\"#{ $fa-var-coins }\"")); }

				&.assign {
					color: $blue;
					@include iconAfter(unquote("\"#{ $fa-var-user-cog }\"")); }

				&.create-invoice {
					color: $green-light;
					@include iconAfter(unquote("\"#{ $fa-var-file-invoice-dollar }\"")); }

				&.assign-item {
					color: $green-light;
					padding: 0;
					@include iconAfter(unquote("\"#{ $fa-var-arrow-right }\"")); }

				&.unassign-item {
					color: $red;
					padding: 0;
					@include iconAfter(unquote("\"#{ $fa-var-arrow-left }\"")); } } } } }
