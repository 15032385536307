.react-autosuggest__container {
	margin-bottom: 1em;
	position: relative;

	.suggestion-input-container {
		position: relative;

		input {
			font-size: 1.125em;
			margin-bottom: 0; }

		.closeButton {
			height: 1em;
			width: 1em;
			right: 2em;
			top: 50%;
			transform: translateY(-50%) rotate(-45deg); } }

	.suggestions-container {
		background-color: $white;
		border: 1px solid transparent;
		border-top-width: 0;
		max-height: 30vh;
		overflow-y: scroll;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 100;

		&.react-autosuggest__suggestions-container--open {
			border-color: $blue; }

		.select-suggestion {
			border-top: 1px solid lighten($gray, 10%);
			padding: 0 1em;
			display: block;
			width: 100%; }

		ul {
			@include removeListFormatting;

			> li:first-of-type .select-suggestion {
				border-top: 0; } } } }

