// Framework files that are silent (don't include any footprint to the output CSS file)
@import "sergeant";

// Reset CSS
@import "~reset-css/sass/reset";

// Framework and plugin files that have footprint
// Inserting the webfont inclusion mixins
@import '~sergeant/footprint/sass/fontFace';

// Grid system
@import '~sergeant/footprint/sass/grid';

// Default layout (body, html, regions)
@import '~sergeant/footprint/sass/layout';

// Main HTML elements
@import '~sergeant/footprint/sass/elements';

// Resetting and preformatting objects of the most common libraries we use
@import '~sergeant/footprint/sass/libraryObjects';

// Navigation elements
@import '~sergeant/footprint/sass/navigation';

// Form elements
@import '~sergeant/footprint/sass/forms';

// Built-in content types
@import '~sergeant/footprint/sass/components';

// UI-UX-related elements
@import '~sergeant/footprint/sass/ui-ux';

// Decoration-related elements
@import '~sergeant/footprint/sass/decoration';

// Visual effect-related elements
@import '~sergeant/footprint/sass/effects';

// Messenger
@import '~sergeant/footprint/sass/messenger';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/variables';

// React grid library
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";

// React DatePicker
@import "~react-datepicker/src/stylesheets/datepicker";

// React Confirm Alert library
@import '~react-confirm-alert/src/react-confirm-alert.css';

// Project-wide formatting of complex HTML objects, like sliders, accordions and (other) third-party libraries
@import "components";

// Layout and regions
@import "regions/layout";
@import "regions/header";
@import "regions/sidebar";
@import "regions/footer";

// Subpages
@import "pages/home";
@import "pages/adwords";
@import "pages/reports";
@import "pages/companies";
@import "pages/notFound";