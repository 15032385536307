#{$btnSelector} {
	@include btn-secondary;

	&[disabled] {
		background-color: $light-gray !important; }

	&.btn-red {
		background-color: $red;

		&:hover {
			background-color: darken($red, 10%); } }

	&.btn-black, &.btn-cancel {
		background-color: $black;

		&:hover {
			background-color: darken($black, 10%); } }

	&.btn-primary {
		@include btn-primary; }

	&.btn-small {
		font-size: 0.75em; } }

.closeButton {
	@include createX(3px);
	cursor: pointer;
	line-height: 1;
	height: 1rem;
	position: absolute;
	right: 1em;
	top: 1em;
	width: 1rem; }

#{$btnSelector-CTA} {
	@include btn-variant($btn-variant-primary...); }

#{$btnSelector-submit} {
	@include btn-variant($btn-variant-secondary...); }

#{$textFieldSelector} {
	@include textField-primary; }

a {
	transition: #{$defaultTransitionTime} color linear; }

form ul {
	@include removeListFormatting;

	.form-control {
		text-align: center; } }

form#login-form {
	display: block;
	max-width: 600px;
	margin: 0 auto;
	width: 100%; }

.dropDownIcon {
	cursor: pointer;
	font-size: 1.5em;
	position: absolute;
	right: 0.5em;
	top: 50%;
	transform: translateY(-50%);
	@include iconAfter(unquote("\"#{ $fa-var-caret-down }\""));

	&.open:after {
		content: unquote("\"#{ $fa-var-caret-up }\""); } }

.inputMaskWrapper select {
	margin: 0;

	&:not([multiple]) + .inputMask:before {
		content: unquote("\"#{ $fa-var-caret-down }\"");
		font-size: 1.5rem; }

	&:focus:not([multiple]) + .inputMask:before {
		content: unquote("\"#{ $fa-var-caret-up }\""); } }

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px; }
