form.form {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;

	> * {
		flex-basis: 49%; }

	.form-group {
		display: flex;
		align-items: stretch;
		flex-basis: 100%;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1em;

		> * {
			flex-basis: 49%; }

		.group-title {
			flex-basis: 100%;
			margin-bottom: 0; } }

	.form-field {
		margin-bottom: 1em;

		.field-title {
			margin-bottom: 0.5em; }

		&.full-width {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex-basis: 100%;

			.field-title {
				display: inline-block;
				padding: 0;
				margin-bottom: 0;
				margin-right: 1em; } } }

	.radio-group {
		display: inline-flex;
		align-items: center;

		input[type='radio'] {
			cursor: pointer;
			margin-bottom: 0;

			+ .inputMask {
				height: 20px;
				transition: $defaultTransitionTime border-color;

				&:before {
					background-color: $blue;
					opacity: 0;
					transition: $defaultTransitionTime opacity; } }

			&:checked + .inputMask {
				border-color: $blue;

				&:before {
					opacity: 1; } } }

		.radio-label {
			border-right: 2px solid $gray;
			cursor: pointer;
			margin-right: 0.5em;
			padding-left: 0.5em;
			padding-right: 0.5em;

			&:last-of-type {
				border-right: 0;
				margin-right: 0;
				padding-right: 0; } } }

	.form-buttons {
		flex-basis: 100%;

		.btn + .btn {
			margin-left: 1em; } } }
