.page-not-found {
	.img-wrapper {
		@include fullWidth;
		display: flex;
		flex-grow: 1;
		align-items: center;
		position: relative;
		img {
			position: relative;
			z-index: 2;
			&.dog {
				max-width: 15%;
				position: absolute;
				bottom: 0;
				left: 40%;
				z-index: 1; } } } }
