html, body, .root-container {
	height: 100%;
	margin-top: 0;
	margin-bottom: 0; }

.page {
	display: flex;
	height: 100%;
	flex-flow: column;

	#{$headerSelector} {
		flex: 0 1 auto;
		@include fullWidth; }

	#{$contentSelector} {
		flex: 1 1 auto;
		display: flex; }

	#{$footerSelector} {
		flex: 0 1 auto;
		@include fullWidth; } }

#{$contentSelector} {
	.main {
		display: flex;
		height: 100%;
		flex-flow: column;
		width: 100%; }

	.sub-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		.page-title {
			flex-basis: 50%;
			margin: 0; }

		.sub-header-buttons {
			display: flex;
			flex-basis: 50%;
			align-items: stretch;
			justify-content: flex-end;
			padding: 1em 0;

			.btn {
				font-size: 0.875rem;

				+ .btn {
					margin-left: 1em; } } }

		.sub-header-section {
			display: flex;
			align-items: center;
			flex-basis: 100%;
			padding-bottom: 1em;

			.sub-header-title {
				flex-basis: 10%;
				margin: 0; }

			.btn + .btn {
				margin-left: 1em; } }

		.sub-header-filters .react-autosuggest__container,
		.sub-header-bulk-actions .inputMaskWrapper {
			flex-basis: 25%;
			margin: 0 1em; } } }

.load-overlay-container {
	display: block;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;

	.load-overlay {
		background-color: $bg-alpha;
		filter: blur(2px);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0; }

	.load-icon {
		@include iconAfter(unquote("\"#{ $fa-var-spinner }\""));
		animation: spin 2s infinite ease-in-out;
		font-size: 3em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); } }
