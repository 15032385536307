#{$headerSelector} {
	background-color: $body-bg;
	border-bottom: 1px solid $dark-gray;
	align-items: stretch;
	justify-content: space-between;
	display: flex;
	padding: 0 1em;

	.logo-container {
		flex-basis: 120px;
		min-width: 80px;
		padding-bottom: 0.5em;
		padding-top: 0.5em; }

	.main-navigation, .account-navigation {
		display: flex; }

	#{$menuUlSelector} {
		display: flex;

		#{$menuLiSelector} {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			padding: 0 1em;
			@include fadeEffect($itemSelector: '.submenu', $visibleState: '&.open .submenu');

			+ #{$menuLiSelector} {
				&:before {
					background-color: $blue;
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					height: 1.5rem;
					transform: translateY(-50%);
					width: 2px; } }

			&.language-selector {
				align-items: center;
				flex-direction: row;

				#{$menuLinkSelector} {
					padding: 0 0.25em;

					&.active {
						color: $gray;
						pointer-events: none; } } }

			#{$menuLinkSelector} {
				display: inline-block;
				font-weight: 600;
				line-height: 2;
				text-decoration: none; }

			#{$menuLinkSelector-active} {
				text-decoration: underline; }

			.submenu {
				background-color: $body-bg;
				border: 1px solid $dark-gray;
				border-top-width: 0;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				width: auto;

				.submenu-link {
					display: block;
					padding: 0.25em 1em;
					position: relative;
					text-decoration: none;
					transition: background-color #{$defaultTransitionTime};
					text-align: center;
					white-space: nowrap;

					&:hover {
						background-color: lighten($gray, 30%); }

					+ .submenu-link:before {
						background-color: $blue;
						content: '';
						display: block;
						height: 1px;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 80%; } } }

			&.open {
				#{$menuLinkSelector} {
					color: $blue; } } } }

	.account-navigation #{$menuUlSelector} #{$menuLiSelector} .submenu {
		left: auto;
		right: -1em;
		transform: none; } }
